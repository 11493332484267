import './App.css';


//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';


//components 
import Header from './components/header';
import Procedimentos from './components/procedimentos';
import QuemSomos from './components/quemSomos';
import Diferencial from './components/diferencial';
import Whats from './components/whatsfixo';
import Footer from './components/footer';
import Line from './components/line';



function App() {
  return (
    <div >
      <Header/>
      <Line/>
      <Procedimentos/>
      <Line/>
      <QuemSomos/>
      <Line/>
      <Diferencial/>
      <Line/>
      <Footer/>
      <Whats/>
    </div>
  );
}

export default App;
