import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

//bootstrap
//import ModalContact from '../modal';


//styles
import styles from './navbar.module.scss'


//img
import Logo from '../../../img/pondera2.png'

//components
import ModalContact from '../../modal';

function NavbarResponsive() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className={styles.navbar_container}>
      <Container>
        <Navbar.Brand href="/" > <img src={Logo} alt='Logo' className={styles.logo}></img></Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav className={styles.navbar_links}>

            {/* <Nav.Link href='#dra'> Dra Valéria Alves Fernandes </Nav.Link> */}
            <Nav.Link href='#quemsomos'> Quem somos </Nav.Link>
            <Nav.Link href='#procedimentos'> Procedimentos </Nav.Link>
            <ModalContact/>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarResponsive;