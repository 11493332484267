import React from 'react';

import styles from './procedimentos.module.scss'

//imagens de procedimentos
import botox from '../../img/botox.jpg'
import peeling from '../../img/peeling (1).jpg'
import limpeza from '../../img/limpeza-de-pele.jpg'
import terapia from '../../img/terapiasinjetaveis.jpg'
import emagracimento from '../../img/emagrecimento.jpg'
import micriagulhamento from '../../img/microagulhamento.jpg'
import tratamentocapilar from '../../img/tratamentocapilar.jpg'
import bioestimuladores from '../../img/bioestimuladores.jpg'
import harmonizacao from '../../img/harminizacaofacialcorporal.jpg'
import fiosdepdo from '../../img/fiosdepdo.jpg'
import massoterapia from '../../img/massagem.jpg'
import preenchimento from '../../img/preenchimentolabial.jpg'
import rinomodelecao from '../../img/rinomodelacao.jpg'
import gerenciamento from '../../img/gerenciamentodemelasma.png'




function Procedimentos() {
    

    return (
      <div className={styles.container}>
        
        <div className={styles.buttonContact}>
            <a onclick="return gtag_report_conversion('https://wa.me/5561999786689?text=Ol%C3%A1%21+vim+pelo+site%21');" href="https://wa.me/5561999786689?text=Ol%C3%A1%21+vim+pelo+site%21" >Marcar avaliação gratuita!</a>
        </div> 
        
        <h1 id='procedimentos'>Procedimentos</h1>
 
        <div className={styles.container_card1}>
            <div className={styles.card}>
                <img src={botox} alt='botox' title='botox'/>
                <h2>Botox</h2>
                <p>Procedimento estético que usa toxina botulínica para suavizar rugas e linhas faciais, com resultados temporários que requerem reaplicações periódicas.</p>
            </div>  

            <div className={styles.card}>
                <img src={limpeza} alt='limpeza' title='limpeza'/>
                <h2>Limpeza de pele</h2>
                <p>Remoção de impurezas e cravos da pele. Envolve higienização, esfoliação, extração, máscaras e proteção solar. Recomendado com moderação, conforme orientação profissional.</p>
            </div>  
            
            <div className={styles.card}>
                <img src={peeling} alt='peeling' title='peeling'/>
                <h2>Peeling</h2>
                <p>Tratamento estético que usa substâncias químicas ou equipamentos para remover camadas superficiais da pele, promovendo renovação celular e tratando manchas, rugas ou cicatrizes.</p>
            </div>  
        </div>
        

        
        <div className={styles.container_card1}>
            <div className={styles.card}>
                <img src={terapia} alt='terapia' title='terapia'/>
                <h2>Terapias injetáveis</h2>
                <p>São tratamentos que utilizam injeções para administrar substâncias estéticas diretamente no corpo. Essa abordagem permite resultados rápidos e precisos, sendo comum em como preenchimentos e botox.</p>
            </div>  

            <div className={styles.card}>
                <img src={emagracimento} alt='emagracimento' title='emagracimento'/>
                <h2>Emagrecimento</h2>
                <p>São procedimentos minimamente invasivos que auxiliam na perda de peso, redução de medidas e modelagem do corpo.</p>
            </div>  
            
            <div className={styles.card}>
                <img src={micriagulhamento} alt='tratamentocapilar' title='tratamentocapilar'/>
                <h2>Microagulhamento</h2>
                <p>Procedimento que usa microagulhas para criar pequenas perfurações na pele, estimulando a produção de colágeno. Melhora a textura e aparência da pele, sendo usado para tratar rugas, cicatrizes de acne e outras imperfeições.</p>
            </div>  
        </div>


        
        <div className={styles.container_card1}>
            <div className={styles.card}>
                <img src={tratamentocapilar} alt='tratamentocapilar' title='tratamentocapilar'/>
                <h2>Tratamento capilar</h2>
                <p>Voltado para melhorar a saúde e aparência dos cabelos e reparar fios danificados, além de estimular o crescimento capilar.</p>
            </div>  

            <div className={styles.card}>
                <img src={bioestimuladores} alt='bioestimuladores' title='bioestimuladores'/>
                <h2>Bioestimuladores</h2>
                <p>São substâncias injetáveis que estimulam a produção de colágeno e ácido hialurônico na pele. Utilizados em tratamentos para rejuvenescimento, flacidez e melhora da textura da pele.</p>
            </div>  
            
            <div className={styles.card}>
                <img src={harmonizacao} alt='harmonizacao' title='harmonizacao'/>
                <h2>Harmonização facial e corporal</h2>
                <p>Busca equilibrar e realçar traços faciais e corporais através de técnicas não cirúrgicas, alcançando uma aparência mais harmônica e proporcional, melhorando contornos e combatendo sinais de envelhecimento.</p>
            </div>  

        </div>
        

        <div className={styles.container_card1}>
            <div className={styles.card}>
                <img src={fiosdepdo} alt='Fios' title='Fios'/>
                <h2>Fios de PDO</h2>
                <p>O procedimento estético de fios de PDO insere suturas absorvíveis na pele para estimular colágeno, melhorando a firmeza e a textura. Com mínima invasão, seu efeito é temporário à medida que os fios degradam. Usado para elevar áreas como sobrancelhas, queixo e pescoço.</p>
            </div>  

            <div className={styles.card}>
                <img src={preenchimento} alt='Preenchimento Labial' title='Preenchimento Labial'/>
                <h2>Preenchimento Labial</h2>
                <p>É um procedimento estético que envolve a injeção de substâncias como ácido hialurônico nos lábios para aumentar seu volume, melhorar contornos e suavizar rugas, resultando em lábios mais cheios e definidos temporariamente.</p>
            </div>  
            
 
            <div className={styles.card}>
                <img src={massoterapia} alt='Massoterapia' title='Massoterapia'/>
                <h2>Massoterapia</h2>
                <p>Envolve a aplicação de técnicas de massagem para melhorar a aparência da pele, reduzir celulite, promover drenagem linfática e relaxamento muscular. Esses tratamentos visam aprimorar a textura da pele, estimular a circulação sanguínea e aliviar tensões, contribuindo para um aspecto mais saudável e  rejuvenescido.</p>
            </div>
        </div>


        <div className={styles.container_card}>

            <div className={styles.card} id={styles.rino}>
                <img src={rinomodelecao} alt='rinomodelecao' title='rinomodelecao'/>
                <h2>Rinomodelação</h2>
                <p>É um procedimento estético não cirúrgico no qual são aplicados preenchimentos, como ácido hialurônico, para alterar a forma e contorno do nariz, corrigindo imperfeições como assimetrias e depressões. Isso pode melhorar a aparência do nariz de maneira temporária e com menos riscos em comparação com a cirurgia plástica tradicional de rinoplastia.</p>
            </div> 

            <div className={styles.card} id={styles.rino}>
                <img src={gerenciamento} alt='Gerenciamento de Melasma' title='Gerenciamento de Melasma'/>
                <h2>Gerenciamento de Melasma</h2>
                <p>Nossa abordagem especializada em gerenciamento de melasma visa restaurar a confiança através de tratamentos personalizados. Combinamos terapias avançadas e produtos de cuidados com a pele para minimizar a hiperpigmentação e proporcionar uma pele mais uniforme e luminosa.</p>
            </div> 
        </div>

      </div>
    );
  }
  
  export default Procedimentos;