import React from 'react';
import styles from './whats.module.scss'

import whats from '../../img/whatsapp-logo-1.png'


// const gerarLink = () => {
//     const numeroTelefone = '5561999217760'; // Substitua com o número de telefone do destinatário
//     const mensagem = 'Olá! vim pelo site'; // Substitua com a mensagem personalizada que você deseja enviar
//     const link = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
//     window.open(link, '_blank');
//   };


function Whats() {
    return (

      <div className={styles.container_whats}>
        {/* <button onClick={gerarLink}> <img src={whats} alt='whatsapp' title='Whatsapp'/> </button> */}
        
        <a onclick="return gtag_report_conversion('https://wa.me/5561999786689?text=Ol%C3%A1%21+vim+pelo+site%21');" href="https://wa.me/5561999786689?text=Ol%C3%A1%21+vim+pelo+site%21"><img src={whats} alt='whatsapp' title='Whatsapp'/></a>

      </div>
    );
  }
  
  export default Whats;