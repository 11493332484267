import NavbarResponsive from "./navbar";
import styles from './header.module.scss'

 import Modelo1 from '../../img/model1.png'

function Header() {
    return (
      <div>
        <NavbarResponsive/>
        <div className={styles.img_container}>
          <div className={styles.img_logo}>
             <img src={Modelo1} alt="img" title="img"/> 
          </div>
        </div>
      </div>
    );
  }
  
  export default Header;
  