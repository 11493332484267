import React from 'react';

import styles from './QuemSomos.module.scss'

import arte from '../../img/arte1c.png'


function QuemSomos() {
    return (

      <div className={styles.container}>
        <img src={arte} alt='arte'/>
        
        <div>

          <h2 id='quemsomos' className={styles.quemsomos}>Quem somos</h2>
          <p>Somos uma equipe apaixonada e dedicada de profissionais da beleza e bem-estar, comprometidos em proporcionar uma experiência transformadora para cada um dos nossos clientes. Na Pondera, acreditamos que a verdadeira beleza começa com a confiança e o amor próprio, e estamos aqui para ajudar você a descobrir e realçar a sua melhor versão. <br/>

          Nossa jornada começou com uma visão: criar um espaço onde os cuidados estéticos vão além da superfície, abrangendo mente, corpo e alma. Ao longo dos anos, consolidamos uma reputação sólida como uma clínica estética de referência, oferecendo tratamentos inovadores e personalizados que atendem às necessidades únicas de cada cliente.<br/><br/></p>
        </div>
        
      </div>
    );
  }
  
  export default QuemSomos;