import styles from './line.module.scss'


function Line() {
    return (
      <div>
        <hr className={styles.animated_hr}/>
      </div>
    );
  }
  
  export default Line;
  