
import styles from './footer.module.scss'

//img
import Logo from '../../img/pondera1.png'
import Whatsapp from '../../img/whatsapp_1384095.png'
import Facebook from  '../../img/facebook_747374.png'
import Instagram from  '../../img/instagram_1384031.png'




const gerarLink = () => {
    const numeroTelefone = '5561999786689'; // Substitua com o número de telefone do destinatário
    const mensagem = 'Olá! vim pelo site'; // Substitua com a mensagem personalizada que você deseja enviar
    const link = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
    window.open(link, '_blank');
  };
  const instagramlink = () => {
    window.open('https://www.instagram.com/ponderaestetica/', '_blank'); // Substitua com a URL do site externo que você deseja abrir em uma nova aba
  };
  const facebooklink = () => {
    window.open('https://www.facebook.com/esteticapondera?mibextid=9R9pXO', '_blank'); // Substitua com a URL do site externo que você deseja abrir em uma nova aba
  };


function Footer() {
    return (

      <div className={styles.container_footer1}>
        <div className={styles.container_footer2}>
            <img src={Logo} alt='Logo Pondera' title='Logo pondera' className={styles.logop}/>

            <div>
                <h2>Fale conosco</h2>
                <p>Telefone:(61) 99978-6689</p>
                <p>E-mail: ponderaestetica@gmail.com </p>
                <p>DF PlazaShopping, Torre A, Sala 1110 Águas Claras, Brasilia/DF</p>
            </div> 

            <div className={styles.container_1}>
                <h2>Horário de Atendimento</h2>
                <p>De segunda a sexta-feira: 08h00 às 18h00 Sábado: 09h00 às 16h00</p>

        
                <div className={styles.container_2}>
                    <h2>Redes Sociais</h2>

                    <div className={styles.container_redes}>
                        <button onClick={gerarLink}> <img src={Whatsapp} alt='whatsApp'></img></button>

                        <button onClick={instagramlink} ><img src={Instagram} alt='instagram'></img></button>
              
                        <button onClick={facebooklink}><img src={Facebook} alt='facebook'></img></button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Footer;
  