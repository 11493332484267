import React from 'react';

import styles from './diferencial.module.scss'

import Logo from '../../img/logo.png'

function Diferencial() {
    return (
      <div className={styles.container_diferencial}>
        <img src={Logo} className={styles.logo}alt='logo'/>
        <div>
          <h2>O que nos diferencia?</h2>
          <p>Seja você um cliente em busca de rejuvenescimento facial, contorno corporal ou relaxamento profundo, estamos aqui para superar suas expectativas. Junte-se a nós na Pondera e embarque em uma jornada de autodescoberta, confiança e beleza duradoura. Estamos ansiosos para recebê-lo em nossa clínica estética e fazer parte da sua história de transformação.</p>
          <p>Equipe Pondera Estética.</p>
        </div>
      </div>
    );
  }
  
  export default Diferencial;
  